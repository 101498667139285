<template>
  <div class="uploading-container">
    <div class="image-wrapper">
      <img :src="imageSrc" alt="Background Image" />
      <form @submit.prevent="onSearch" class="search-form">
        <van-search
          v-model="value"
          placeholder="正在输入......"
          show-action
          @search="onSearch"
          @cancel="onCancel"
          class="search-box"
        />
      </form>
    </div>

    <!-- 下拉菜单仅在有匹配项时显示 -->
    <ul class="dropdown-list" v-if="showDropdown">
      <li
        v-for="option in filteredCompanyOptions"
        :key="option.id"
        @click="handleSelect(option)"
        class="dropdown-item"
      >
        {{ option.name }}
      </li>
    </ul>

    <div class="image-list">
      <div
        class="image-item"
        v-for="(image, index) in images"
        :key="index"
        @click="navigateToRoute(index)"
      >
        <img :src="image" alt="图片" loading="lazy" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// 定义一个过滤函数，去除非法字符或特殊符号
function filterSpecialCharacters(str) {
  return str ? str.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, "") : "";
}

export default {
  data() {
    return {
      value: "",
      filesOptions: [], // 文件名列表
      selectedFile: "", // 用户选择的文件名
      showDropdown: false, // 是否显示下拉框
      imageSrc:
        "http://wx.servodynamics.com.cn/source/static/file_image/05.jpg",
      images: [
        "http://wx.servodynamics.com.cn/source/static/file_image/02.png",
        "http://wx.servodynamics.com.cn/source/static/file_image/03.png",
        "http://wx.servodynamics.com.cn/source/static/file_image/04.png",
      ],
      // 定义图片的路由
      imageRoutes: [
        { path: "/sample", props: { search_in_folder: "392001550885" } },
        { path: "/poa", props: { search_in_folder: "392001550884" } },
        { path: "/certification", props: { search_in_folder: "392001556918" } },
      ],
    };
  },

  computed: {
    filteredCompanyOptions() {
      const query = filterSpecialCharacters(this.value).toLowerCase();
      return Array.isArray(this.filesOptions)
        ? this.filesOptions.filter((option) =>
            option && typeof option === "object" && "name" in option
              ? filterSpecialCharacters(option.name)
                  .toLowerCase()
                  .includes(query)
              : false
          )
        : [];
    },
  },
  methods: {
    navigateToRoute(index) {
      if (this.imageRoutes[index]) {
        this.$router.push(this.imageRoutes[index]);
      }
    },
    async onSearch() {
      try {
        const response = await axios.get(
          "http://wx.servodynamics.com.cn:9527/api/fill_search",
          {
            params: {
              query_words: filterSpecialCharacters(this.value),
            },
          }
        );
        const items = response.data
          .map((item) => ({
            name: item.file_name || "", // 文件名
            id: item.file_id,
            search_in_folder: item.main_id,
          }))
          .filter((item) => item.name);
        if (items.length > 0) {
          this.filesOptions = items;
          this.showDropdown = true;
        } else {
          console.log("No matching items found");
          this.showDropdown = false;
        }
      } catch (error) {
        console.error("There was an error fetching the search results!", error);
        alert("搜索失败，请稍后再试");
      }
    },
    handleSelect(option) {
      this.selectedFile = option;
      this.value = option.name; // 将选中的文件名设置为搜索框的值

      this.showDropdown = false;

      const targetRoute = this.imageRoutes.find(
        (route) => route.props.search_in_folder === option.search_in_folder
      );
      if (targetRoute) {
        this.$router
          .push({
            path: targetRoute.path,
            query: { id: option.id }, // 传递文件ID作为查询参数
          })
          .catch((err) => {
            console.error("Navigation failed:", err);
          });
      }
    },
    onCancel() {
      this.value = ""; // 清除输入框内容
      this.showDropdown = false; // 隐藏下拉框
      console.log("取消");
    },
  },
};
</script>
<style lang="less" scoped>
html,
body {
  height: 100%;
  margin: 0;
}
.user-list ul {
  list-style-type: none;
  padding: 0;
}

.user-list li {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}
.uploading-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
}

.image-wrapper {
  position: relative;
  width: 90%;
  max-width: 1200px;
  height: 200px;
  overflow: hidden;
  margin-bottom: 20px;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.search-box {
  width: 100%;
  box-sizing: border-box;
}

/* 图片列表样式 */
.image-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0.7;
  overflow-y: auto;
  gap: 5px; /* 减小行与行之间的间距 */
}

.image-item {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.image-item img {
  width: 100%;
  height: 62%;
  object-fit: fill; /* 确保图片完全显示且不失真 */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* 下拉列表样式 */
.dropdown-list {
  margin-top: 10px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  list-style-type: none;
  padding: 0;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
/* 针对小屏幕设备（如手机） */
@media (max-width: 480px) {
  .image-wrapper {
    width: 100%; /* 在小屏幕上填充整个宽度 */
    max-width: none; /* 禁用最大宽度 */
  }
  .search-form {
    width: 90%; /* 搜索框占据90%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }
  .search-box {
    font-size: 14px; /* 减小字体大小 */
  }
}

/* 针对平板设备 */
@media (min-width: 481px) and (max-width: 768px) {
  .image-wrapper {
    width: 85%; /* 中等宽度 */
  }

  .search-form {
    width: 80%; /* 搜索框占据80%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 16px; /* 标准字体大小 */
  }
}

/* 针对小型笔记本电脑和平板 */
@media (min-width: 769px) and (max-width: 1024px) {
  .image-wrapper {
    width: 80%; /* 稍窄一点 */
  }

  .search-form {
    width: 70%; /* 搜索框占据70%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 18px; /* 更大的字体大小 */
  }
}

/* 针对桌面显示器 */
@media (min-width: 1025px) {
  .image-wrapper {
    width: 100%; /* 较窄宽度 */
  }

  .search-form {
    width: 60%; /* 搜索框占据60%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 20px; /* 更大的字体大小 */
  }
}
</style>