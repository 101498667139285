<template>
  <div class="uploading-container">
    <div class="image-wrapper">
      <img :src="imageSrc" alt="Background Image" />
      <form @submit.prevent="onSearch" class="search-form">
        <van-search
          v-model="value"
          placeholder="查询......"
          show-action
          @search="onSearch"
          @cancel="onCancel"
          class="search-box"
        />
      </form>
    </div>

    <!-- 下拉菜单仅在有匹配项时显示 -->
    <ul class="dropdown-list" v-if="showDropdown">
      <li
        v-for="option in filteredCompanyOptions"
        :key="option"
        @click="handleSelect(option)"
        class="dropdown-item"
      >
        {{ option }}
      </li>
    </ul>
    <div class="image-container">
      <img :src="imageSrc1" alt="sample Image" class="sample-image" />
      <div class="item-list-overlay">
        <ul>
          <li v-for="(item, index) in items" :key="index" :data-id="item.id">
            <span :data-original-name="item.originalName">{{ item.name }}</span>
            <button @click="handlePreview(item)" class="preview-btn">
              预览
            </button>
            <!-- <button @click="handleDownload(item)">下载</button> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// 定义一个过滤函数，去除非法字符或特殊符号
function filterSpecialCharacters(str) {
  return str ? str.replace(/[^\u4e00-\u9fa5a-zA-Z0-9]/g, "") : "";
}
// 定义一个辅助函数用于截断字符串
function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}
export default {
  props: {
    search_in_folder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: "",
      originalItems: [], // 文件名列表
      selectedFile: "", // 用户选择的文件名
      showDropdown: false, // 是否显示下拉框
      //   search_in_folder: "392001550884",
      imageSrc:
        "http://wx.servodynamics.com.cn/source/static/file_image/05.jpg",
      imageSrc1:
        "http://wx.servodynamics.com.cn/source/static/file_image/05.png",
      items: [],
    };
  },

  computed: {
    filteredCompanyOptions() {
      const query = filterSpecialCharacters(this.value).toLowerCase();
      return this.originalItems
        .filter((option) =>
          filterSpecialCharacters(option.name).toLowerCase().includes(query)
        )
        .map((option) => option.name); // 返回原始文件名
    },
  },
  watch: {
    "$route.query.fileId"(newId) {
      if (newId) {
        this.scrollToFile(newId);
      }
    },
  },
  created() {
    this.get_file_info();
  },
  mounted() {
    const fileId = this.$route.query.id;
    if (fileId) {
      this.scrollToFile(fileId);
    }
  },
  methods: {
    scrollToFile(id) {
      this.$nextTick(() => {
        const element = document.querySelector(`[data-id="${id}"]`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    async onSearch() {
      try {
        const response = await axios.get(
          "http://wx.servodynamics.com.cn:9527/api/fuzzy_search",
          {
            params: {
              search_in_folder: this.search_in_folder,
              query_words: filterSpecialCharacters(this.value),
            },
          }
        );

        const files = response.data?.files || [];
        const items = files.map((item) => item.name); // 假设您想要的是文件名

        if (items.length > 0) {
          this.filesOptions = items;
          this.showDropdown = true;
        } else {
          alert("No matching files found:");
          this.showDropdown = false;
        }
      } catch (error) {
        console.error("There was an error fetching the search results!", error);
        alert("搜索失败，请稍后再试");
      }
    },
    handleSelect(option) {
      this.selectedFile = option;
      this.value = option; // 将选中的文件名设置为搜索框的值

      this.showDropdown = false;

      // 调用新添加的方法，滚动到选定项并添加特效
      this.scrollItemAndHighlight(option);
    },
    scrollItemAndHighlight(optionName) {
      this.$nextTick(() => {
        // 找到对应的元素
        const items = this.$el.querySelectorAll(".item-list-overlay ul li");
        for (let item of items) {
          const spanElement = item.querySelector("span");
          if (spanElement.dataset.originalName === optionName) {
            item.scrollIntoView({ behavior: "smooth" });
            this.highlightItem(item);
            break;
          }
        }
      });
    },
    highlightItem(element) {
      element.classList.add("highlighted");
      setTimeout(() => {
        element.classList.remove("highlighted"); // 5秒后恢复默认背景
      }, 5000);
    },
    scrollToFileAndHighlight(id) {
      this.$nextTick(() => {
        const element = document.querySelector(`[data-id="${id}"]`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          this.highlightItem(element); // 高亮显示
        }
      });
    },
    onCancel() {
      this.value = ""; // 清除输入框内容
      this.showDropdown = false; // 隐藏下拉框
      console.log("取消");
    },
    async handlePreview(item) {
      try {
        const res = await axios.get("http://wx.servodynamics.com.cn:9527/api/file_list_do", {
          params: {
            main_id: this.search_in_folder,
            file_id: item.id,
          },
        });
        if (res.data.code === "ok" && res.data.data.length > 0) {
          const videoData = res.data.data[0];
          // 获取 registerId 从localstorage
          const registerId = localStorage.getItem("registerId");

          // 获取预览token
          const tokenRes = await axios.post(
            "http://wx.servodynamics.com.cn:9527/api/preview/token",
            {
              file_id: videoData.file_id,
              period: 7200,
              registerId: registerId,
            }
          );
          if (tokenRes.data.code === "success") {
            const previewUrl = tokenRes.data.preview_url;
            window.location.href = previewUrl;
          } else {
            console.error(
              "Failed to get preview URL:",
              previewUrlRes.data.message
            );
          }
        }
      } catch (error) {
        console.error("Error fetching video list:", error);
        console.log("加载视频列表时出错");
      }
    },
    handleDownload(item) {
      console.log(`Downloading: ${item.name}`);
    },
    async get_file_info() {
      try {
        const res = await axios.get("http://wx.servodynamics.com.cn:9527/api/get_file_info", {
          params: {
            file_id: this.search_in_folder,
          },
        });
        const maxNameLength = 13;
        this.originalItems = res.data.files.map((file) => ({
          name: file.name,
          id: file.id,
        }));
        this.items = this.originalItems.map((file) => ({
          name: truncateString(file.name, maxNameLength),
          originalName: file.name, // 添加原始文件名属性
          id: file.id,
        }));
        // 文件列表加载完毕后，尝试滚动到指定文件并高亮显示
        this.$nextTick(() => {
          const fileId = this.$route.query.id;
          if (fileId) {
            this.scrollToFileAndHighlight(fileId);
          }
        });
      } catch (error) {
        console.error("There was an error fetching the search results!", error);
      }
    },
  },
};
</script>
      <style lang="less" scoped>
html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}
@keyframes breath {
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(189, 21, 21, 0.3); /* 黑色半透明 */
    // background-color: rgba(255, 255, 255, 0.3);
  }
}
// .highlighted {
//   background-color: black;
//   transition: background-color 0.5s;
// }
.highlighted {
  animation-name: breath;
  animation-duration: 3s; /* 动画持续时间 */
  animation-iteration-count: infinite; /* 无限循环 */
  animation-timing-function: ease-in-out; /* 加速减速 */
  transition: background-color 0.5s;
}

.uploading-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.item-list-overlay ul {
  max-height: 600px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.image-wrapper {
  position: relative;
  width: 90%;
  max-width: 1200px;

  overflow: hidden;
  margin-bottom: 10px;
}

.image-wrapper img {
  width: 100%;
  object-fit: cover;
  display: block;
}

.search-box {
  width: 100%;
  box-sizing: border-box;
}
/* 下拉列表样式 */
.dropdown-list {
  margin-top: 10px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  list-style-type: none;
  padding: 0;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
.image-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
}
.sample-image {
  width: 100%;
  height: auto; /* 确保图片按比例缩放 */
  display: block;
}

.item-list-overlay {
  position: absolute;
  top: -12px;
  left: 0;
  right: 0; /* 半透明背景色 */
  padding: 20px;
  border-radius: 0 0 8px 8px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  span {
    flex-grow: 1;
    color: white;
    margin-right: 20px;
  }

  button {
    background-color: transparent;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: color 0.3s;

    &:hover {
      color: #d4edda;
    }

    &.preview-btn {
      margin-right: 10px;
    }
  }
}
/* 针对小屏幕设备（如手机） */
@media (max-width: 480px) {
  .image-wrapper {
    width: 100%; /* 在小屏幕上填充整个宽度 */
    max-width: none; /* 禁用最大宽度 */
  }
  .search-form {
    width: 90%; /* 搜索框占据90%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }
  .search-box {
    font-size: 14px; /* 减小字体大小 */
  }
  .sample-image {
    width: 100%;
    max-width: 100%;
    height: 600px;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }
}

/* 针对平板设备 */
@media (min-width: 481px) and (max-width: 768px) {
  .image-wrapper {
    width: 85%; /* 中等宽度 */
  }

  .search-form {
    width: 80%; /* 搜索框占据80%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 16px; /* 标准字体大小 */
  }
}

/* 针对小型笔记本电脑和平板 */
@media (min-width: 769px) and (max-width: 1024px) {
  .image-wrapper {
    width: 80%; /* 稍窄一点 */
  }

  .search-form {
    width: 70%; /* 搜索框占据70%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 18px; /* 更大的字体大小 */
  }
}

/* 针对桌面显示器 */
@media (min-width: 1025px) {
  .image-wrapper {
    width: 100%; /* 较窄宽度 */
  }

  .search-form {
    width: 60%; /* 搜索框占据60%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 20px; /* 更大的字体大小 */
  }
}
</style>