import Vue from 'vue'
import App from './App.vue'
import SlideVerify from './views/captcha/index'
// 路由
import router from './router'

import Vuex from 'vuex';
// 仓库
import store from './store'

// vant组件
import Vant, { CountDown, Field, Form, Search } from 'vant'
import 'vant/lib/index.less'

// swiper组件
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'

// 引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import wx from 'jweixin-module'

import 'weui'; // 引入 WeUI 样式

// 全局定义字体css样式
import '@/assets/style/fonts.css'
// 倒计时
Vue.use(CountDown);
Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(Vuex);
Vue.use(Field);
Vue.use(Form);
Vue.use(Search);
Vue.use(SlideVerify);
Vue.use(VueAwesomeSwiper);
Vue.prototype.$wx = wx;

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
